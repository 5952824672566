<template>
    <div class="container-fluid box">
        <h2 class="my-3">Endpoints</h2>
        <p class="h6">Route : https://api.nextlevel.link/external</p>
        <h4 class="mt-3">{{ $t('profil.api-limit') }} :</h4>
        <p class="h6">{{ $t('profil.api-request-time') }} : 60</p>
        <h4 class="mt-3">Authentification</h4>
        <!-- DIV -->
        <p>
            <a
                class="box h5"
                @click="
                    () => {
                        displayDocumentation.postLogin = !displayDocumentation.postLogin
                    }
                "
            >
                <span class="badge p-2 badge-warning mr-2">POST</span>/login
                <span class="ml-5 h6">{{ $t('profil.api-auth-login') }}</span>
            </a>
        </p>
        <div v-if="displayDocumentation.postLogin" class="box">
            <p class="font-weight-bold h5">Body :</p>

            <table class="table">
                <thead class="thead-light">
                    <tr>
                        <th scope="col">
                            {{ $t('profil.api-key') }}
                        </th>
                        <th scope="col">
                            {{ $t('profil.api-value') }}
                        </th>
                        <th scope="col">
                            {{ $t('profil.api-exemple') }}
                        </th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>api_key</td>
                        <td>{{ $t('profil.api-nextlevel-token') }}</td>
                        <td>8fbd1489133c4ae8879212a63d5876e7</td>
                    </tr>
                </tbody>
            </table>
            <p class="font-weight-bold h5">
                <u>Réponse :</u>
            </p>
            <pre><code class='w-100'>{
    "token":"1|e3504d2dfd2cd87cfd6e22199322a90580f6c6f29c3d0f05eb53c2c4319bb0a2"
}
      </code></pre>
        </div>
        <!-- FIN DIV -->
        <h4>Partners</h4>
        <!-- DIV -->
        <p>
            <a
                class="box h5"
                @click="
                    () => {
                        displayDocumentation.getPartnerOrders = !displayDocumentation.getPartnerOrders
                    }
                "
            >
                <span class="badge p-2 badge-success mr-2">GET</span>/partners/orders
                <span class="ml-5 h6">{{ $t('profil.api-partner-order') }}</span>
            </a>
        </p>
        <div v-if="displayDocumentation.getPartnerOrders" class="box">
            <p class="font-weight-bold h5">
                <u>Headers :</u>
            </p>

            <table class="table">
                <thead class="thead-light">
                    <tr>
                        <th scope="col">
                            {{ $t('profil.api-key') }}
                        </th>
                        <th scope="col">
                            {{ $t('profil.api-value') }}
                        </th>
                        <th scope="col">
                            {{ $t('profil.api-exemple') }}
                        </th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>Authorization</td>
                        <td>{{ $t('profil.api-getted-token') }}</td>
                        <td>Bearer 1|e3504d2dfd2cd87cfd6e22199322a90580f6c6f29c3d0f05eb53c2c4319bb0a2</td>
                    </tr>
                </tbody>
            </table>
            <hr class="solid my-4" />
            <p class="font-weight-bold h5">
                <u>Params :</u>
            </p>

            <table class="table">
                <thead class="thead-light">
                    <tr>
                        <th scope="col">
                            {{ $t('profil.api-key') }}
                        </th>
                        <th scope="col">
                            {{ $t('profil.api-value') }}
                        </th>
                        <th scope="col">
                            {{ $t('profil.api-exemple') }}
                        </th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>per_page (optionnel)</td>
                        <td>{{ $t('profil.api-number-getted') }}</td>
                        <td>per_page=100</td>
                    </tr>
                </tbody>
            </table>
            <p class="font-weight-bold h5">
                <u>Réponse :</u>
            </p>
            <pre><code class='w-100'>
    {
        "orders": {
            "current_page": 1,
            "data": [
                {
                    "ref": 0,
                    "status": "annule",
                    "date_acceptation_ou_refus_partenaire": null,
                    "lang": "fr_fr",
                    "bought_kw": "...",
                    "keywords": "...",
                    "content": null,
                    "brief": "RAS",
                    "redaction": "proprietaire",
                    "page": "...",
                    "link_url": "...",
                    "link_anchor": "...",
                    "link_second_url": "",
                    "link_second_anchor": "",
                    "publication_date": null,
                    "creation_date": "2019-04-01 22:20:53",
                    "gain": 0
                }
            ],
            "first_page_url": "https://app.nextlevel.link/external/partners/orders?page=1",
            "from": 1,
            "last_page": 1,
            "last_page_url": "https://app.nextlevel.link/external/partners/orders?page=1",
            "links": [
                {
                    "url": null,
                    "label": "&laquo; Previous",
                    "active": false
                },
                {
                    "url": "https://app.nextlevel.link/external/partners/orders?page=1",
                    "label": "1",
                    "active": true
                },
                {
                    "url": null,
                    "label": "Next &raquo;",
                    "active": false
                }
            ],
            "next_page_url": null,
            "path": "https://app.nextlevel.link/external/partners/orders",
            "per_page": 20,
            "prev_page_url": null,
            "to": 1,
            "total": 1
        }
    }
      </code></pre>
        </div>
        <!-- FIN DIV -->
        <!-- DIV -->
        <p>
            <a
                class="box h5"
                @click="
                    () => {
                        displayDocumentation.getPartnerNeeds = !displayDocumentation.getPartnerNeeds
                    }
                "
            >
                <span class="badge p-2 badge-success mr-2">GET</span>/partners/needs
                <span class="ml-5 h6">{{ $t('profil.api-partner-needs') }}</span>
            </a>
        </p>
        <div v-if="displayDocumentation.getPartnerNeeds" class="box">
            <p class="font-weight-bold h5">
                <u>Headers :</u>
            </p>

            <table class="table">
                <thead class="thead-light">
                    <tr>
                        <th scope="col">
                            {{ $t('profil.api-key') }}
                        </th>
                        <th scope="col">
                            {{ $t('profil.api-value') }}
                        </th>
                        <th scope="col">
                            {{ $t('profil.api-exemple') }}
                        </th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>Authorization</td>
                        <td>{{ $t('profil.api-getted-token') }}</td>
                        <td>Bearer 1|e3504d2dfd2cd87cfd6e22199322a90580f6c6f29c3d0f05eb53c2c4319bb0a2</td>
                    </tr>
                </tbody>
            </table>
            <hr class="solid my-4" />
            <p class="font-weight-bold h5">
                <u>Params :</u>
            </p>

            <table class="table">
                <thead class="thead-light">
                    <tr>
                        <th scope="col">
                            {{ $t('profil.api-key') }}
                        </th>
                        <th scope="col">
                            {{ $t('profil.api-value') }}
                        </th>
                        <th scope="col">
                            {{ $t('profil.api-exemple') }}
                        </th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>per_page (optionnel)</td>
                        <td>{{ $t('profil.api-number-getted') }}</td>
                        <td>per_page=100</td>
                    </tr>
                </tbody>
            </table>
            <p class="font-weight-bold h5">
                <u>Réponse :</u>
            </p>
            <pre><code class='w-100'>
    {
        "needs": {
            "current_page": 1,
            "data": [
                {
                    "id": 0,
                    "status": "Attente positionnement",
                    "lang": "fr",
                    "created_at": "...",
                    "keyword": "...",
                    "secondaryKeywords": [
                        {
                            "name": "..."
                        },
                        {
                            "name": "..."
                        },
                        {
                            "name": "..."
                        }
                    ],
                    "nbArticle": 10,
                    "brief": null,
                    "theme": "General"
                },
            ],
            "first_page_url": "https://app.nextlevel.link/external/partners/needs?page=1",
            "from": 1,
            "last_page": 37,
            "last_page_url": "https://app.nextlevel.link/external/partners/needs?page=37",
            "links": [
                {
                    "url": null,
                    "label": "&laquo; Previous",
                    "active": false
                },
                {
                    "url": "https://app.nextlevel.link/external/partners/needs?page=1",
                    "label": "1",
                    "active": true
                },
                {
                    "url": "https://app.nextlevel.link/external/partners/needs?page=2",
                    "label": "2",
                    "active": false
                },
            ],
            "next_page_url": "https://app.nextlevel.link/external/partners/needs?page=2",
            "path": "https://app.nextlevel.link/external/partners/needs",
            "per_page": 100,
            "prev_page_url": null,
            "to": 100,
            "total": 3607
        }
    }
      </code></pre>
        </div>
        <!-- FIN DIV -->
        <!-- DIV -->
        <p>
            <a
                class="box h5"
                @click="
                    () => {
                        displayDocumentation.getSites = !displayDocumentation.getSites
                    }
                "
            >
                <span class="badge p-2 badge-success mr-2">GET</span>/partners/sites
                <span class="ml-5 h6">{{ $t('profil.api-partner-sites') }}</span>
            </a>
        </p>
        <div v-if="displayDocumentation.getSites" class="box">
            <p class="font-weight-bold h5">
                <u>Headers :</u>
            </p>

            <table class="table">
                <thead class="thead-light">
                    <tr>
                        <th scope="col">
                            {{ $t('profil.api-key') }}
                        </th>
                        <th scope="col">
                            {{ $t('profil.api-value') }}
                        </th>
                        <th scope="col">
                            {{ $t('profil.api-exemple') }}
                        </th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>Authorization</td>
                        <td>{{ $t('profil.api-getted-token') }}</td>
                        <td>Bearer 1|e3504d2dfd2cd87cfd6e22199322a90580f6c6f29c3d0f05eb53c2c4319bb0a2</td>
                    </tr>
                </tbody>
            </table>
            <hr class="solid my-4" />
            <p class="font-weight-bold h5">
                <u>Params :</u>
            </p>

            <table class="table">
                <thead class="thead-light">
                    <tr>
                        <th scope="col">
                            {{ $t('profil.api-key') }}
                        </th>
                        <th scope="col">
                            {{ $t('profil.api-value') }}
                        </th>
                        <th scope="col">
                            {{ $t('profil.api-exemple') }}
                        </th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>per_page (optionnel)</td>
                        <td>{{ $t('profil.api-number-getted') }}</td>
                        <td>per_page=100</td>
                    </tr>
                    <tr>
                        <td>page (optionnel)</td>
                        <td>{{ $t('profil.page-number') }}</td>
                        <td>page=1</td>
                    </tr>
                </tbody>
            </table>
            <p class="font-weight-bold h5">
                <u>Réponse :</u>
            </p>
            <pre><code class='w-100'>
{
    "sites": {
        "current_page": 1,
        "data": [
            {
                "id": 00000,
                "ndd": ".....",
                "health": null,
                "contenu_redige_max": 700,
                "redaction": "nextlevel",
                "actif": 1,
                "accept_preorders_auto": 0,
                "accept_auto_orders_at": null,
                "refus_concurrent_mfa": "non",
                "lien_secondaire_autorise": 1,
                "ancres_acceptees": "toutes",
                "planification_autorisee": 1,
                "prix_minimum_base": 0,
                "group_id": 1,
                "stats_count": 0,
                "contents_count": 0,
                "preorders_count": 0
            }
        ],
        "first_page_url": "https://app.nextlevel.link/external/partners/sites?page=1",
        "from": 1,
        "last_page": 9,
        "last_page_url": "https://app.nextlevel.link/external/partners/sites?page=9",
        "links": [
            {
                "url": null,
                "label": "&laquo; Previous",
                "active": false
            },
            {
                "url": "https://app.nextlevel.link/external/partners/sites?page=1",
                "label": "1",
                "active": true
            },
            {
                "url": "https://app.nextlevel.link/external/partners/sites?page=2",
                "label": "2",
                "active": false
            },
            ...
            {
                "url": "https://app.nextlevel.link/external/partners/sites?page=2",
                "label": "Next &raquo;",
                "active": false
            }
        ],
        "next_page_url": "https://app.nextlevel.link/external/partners/sites?page=2",
        "path": "https://app.nextlevel.link/external/partners/sites",
        "per_page": "1",
        "prev_page_url": null,
        "to": 1,
        "total": 9
    }
}
      </code></pre>
        </div>
        <!-- FIN DIV -->
        <!-- DIV -->
        <p>
            <a
                class="box h5"
                @click="
                    () => {
                        displayDocumentation.getPreorders = !displayDocumentation.getPreorders
                    }
                "
            >
                <span class="badge p-2 badge-success mr-2">GET</span>/partners/preorders
                <span class="ml-5 h6">{{ $t('profil.api-partner-preorders') }}</span>
            </a>
        </p>
        <div v-if="displayDocumentation.getPreorders" class="box">
            <p class="font-weight-bold h5">
                <u>Headers :</u>
            </p>

            <table class="table">
                <thead class="thead-light">
                    <tr>
                        <th scope="col">
                            {{ $t('profil.api-key') }}
                        </th>
                        <th scope="col">
                            {{ $t('profil.api-value') }}
                        </th>
                        <th scope="col">
                            {{ $t('profil.api-exemple') }}
                        </th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>Authorization</td>
                        <td>{{ $t('profil.api-getted-token') }}</td>
                        <td>Bearer 1|e3504d2dfd2cd87cfd6e22199322a90580f6c6f29c3d0f05eb53c2c4319bb0a2</td>
                    </tr>
                </tbody>
            </table>
            <hr class="solid my-4" />
            <p class="font-weight-bold h5">
                <u>Params :</u>
            </p>

            <table class="table">
                <thead class="thead-light">
                    <tr>
                        <th scope="col">
                            {{ $t('profil.api-key') }}
                        </th>
                        <th scope="col">
                            {{ $t('profil.api-value') }}
                        </th>
                        <th scope="col">
                            {{ $t('profil.api-exemple') }}
                        </th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>per_page (optionnel)</td>
                        <td>{{ $t('profil.api-number-getted') }}</td>
                        <td>per_page=100</td>
                    </tr>
                    <tr>
                        <td>page (optionnel)</td>
                        <td>{{ $t('profil.page-number') }}</td>
                        <td>page=1</td>
                    </tr>
                </tbody>
            </table>
            <p class="font-weight-bold h5">
                <u>Réponse :</u>
            </p>
            <pre><code class='w-100'>
{
    "preorders": {
        "current_page": 1,
        "preorders": [
            {
                "id": 0000,
                "site_id": 0000,
                "status": "in_progress",
                "keyword": "...",
                "words_count": 500,
                "meta": "Meta",
                "title": "Title",
                "url": null
            },
            ...
        ],
        "first_page_url": "https://app.nextlevel.link/external/partners/preorders?page=1",
        "from": 1,
        "last_page": 10,
        "last_page_url": "https://app.nextlevel.link/external/partners/preorders?page=10",
        "links": [
            {
                "url": null,
                "label": "&laquo; Previous",
                "active": false
            },
            {
                "url": "https://app.nextlevel.link/external/partners/preorders?page=1",
                "label": "1",
                "active": true
            },
            {
                "url": "https://app.nextlevel.link/external/partners/preorders?page=2",
                "label": "2",
                "active": false
            },
            ...
            {
                "url": "https://app.nextlevel.link/external/partners/preorders?page=2",
                "label": "Next &raquo;",
                "active": false
            }
        ],
        "next_page_url": "https://app.nextlevel.link/external/partners/preorders?page=2",
        "path": "https://app.nextlevel.link/external/partners/preorders",
        "per_page": "1",
        "prev_page_url": null,
        "to": 1,
        "total": 10
    }
}
      </code></pre>
        </div>
        <!-- FIN DIV -->
        <!-- DIV -->
        <p>
            <a
                class="box h5"
                @click="
                    () => {
                        displayDocumentation.getGroups = !displayDocumentation.getGroups
                    }
                "
            >
                <span class="badge p-2 badge-success mr-2">GET</span>/partners/groups
                <span class="ml-5 h6">{{ $t('profil.api-partner-groups') }}</span>
            </a>
        </p>
        <div v-if="displayDocumentation.getGroups" class="box">
            <p class="font-weight-bold h5">
                <u>Headers :</u>
            </p>

            <table class="table">
                <thead class="thead-light">
                    <tr>
                        <th scope="col">
                            {{ $t('profil.api-key') }}
                        </th>
                        <th scope="col">
                            {{ $t('profil.api-value') }}
                        </th>
                        <th scope="col">
                            {{ $t('profil.api-exemple') }}
                        </th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>Authorization</td>
                        <td>{{ $t('profil.api-getted-token') }}</td>
                        <td>Bearer 1|e3504d2dfd2cd87cfd6e22199322a90580f6c6f29c3d0f05eb53c2c4319bb0a2</td>
                    </tr>
                </tbody>
            </table>
            <hr class="solid my-4" />
            <p class="font-weight-bold h5">
                <u>Params :</u>
            </p>

            <table class="table">
                <thead class="thead-light">
                    <tr>
                        <th scope="col">
                            {{ $t('profil.api-key') }}
                        </th>
                        <th scope="col">
                            {{ $t('profil.api-value') }}
                        </th>
                        <th scope="col">
                            {{ $t('profil.api-exemple') }}
                        </th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>lang (optionnel)</td>
                        <td>{{ $t('profil.api-partner-lang') }}</td>
                        <td>lang=fr</td>
                    </tr>
                </tbody>
            </table>
            <p class="font-weight-bold h5">
                <u>Réponse :</u>
            </p>
            <pre><code class='w-100'>
{
    "groups": [
        {
            "id": 1,
            "name": "Généraliste"
        },
        {
            "id": 4,
            "name": "Voyages & Hôtellerie"
        },
        ...
    ]
}
      </code></pre>
        </div>
        <!-- FIN DIV -->
    </div>
</template>

<script>
    export default {
        name: 'APIDocumentation',
        data() {
            return {
                displayDocumentation: {
                    postLogin: false,
                    getPartnerOrders: false,
                    getPartnerNeeds: false,
                    getSites: false,
                    getPreorders: false,
                    getGroups: false
                }
            }
        }
    }
</script>

<style scoped>
    hr.solid {
        border-top: 2px solid rgb(224, 202, 0);
    }
</style>
